import React, { PureComponent, SyntheticEvent, ImgHTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Modal from '../components/modal'
import Layout from '../components/layout'
import Section from '../components/section/section'
import Slider from 'react-slick'
import { WindowLocation } from '@reach/router'

import theme from '../theme'
import EquipmentItem from '../components/equipmentItem'
import ImageCarousel from '../components/imageCarousel'
import { DisplayItem } from '../components/imageCarousel'

export const FirstSectionWrapper = styled.div`
  max-height: calc(100vw * 9/16);
  margin-top: -100px;
  box-shadow: 0px 2px 10px 0px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  @media(max-width: 768px) {
    margin-top: -56px;
  }
`

export const FirstSection = styled.img`
  width: 100vw;
  max-height: inherit;
`

export const FirstSectionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 120px;
  left: 40px;

  @media(max-width: 768px) {
    top: 66px;
    left: 20px
  }
`

export const FirstSectionText = styled.p<{top: number}>`
  font-size: 3.5rem;
  font-weight: 500;
  color: white;
  left: 20px;
  font-family: roboto;
  margin: 0;

  @media(max-width: 768px) {
    font-size: 17px;
  }
`

export const HeaderBar = styled.div`
  width: 100%;
  background-color: #313131;
  position: relative;
  z-index: 0;
`

export const HeaderBarText = styled.p`
  font-size: 1.5rem;
  color: white;
  text-align: center;
  margin: 0;
  padding: 10px;

  @media(max-width: 520px) {
    font-size: 1.25rem;
  }
`

export const HeaderBarBottom = styled.div`
  height: 20px;
  background-color: #232323;
`

const StudioImageWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
`

const StudioImageWrapperA = styled.div`
  max-width: calc(50vw - 2.5px);
  max-height: calc(100vh * 9/16);
  pointer-events: none;
  position: relative;
  width: 100vw;
  height: 100%;

  @media(min-width: 1528px) {
    max-width: calc(89vw * 9/16 - 3.75px);
    max-height: unset;
  }

  @media(max-width: 768px) {
    max-width: unset;
  }
`

const StudioImageWrapperB = styled.div`
  max-width: calc(50vw - 2.5px);
  max-height: calc(100vh * 9/16);
  pointer-events: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(min-width: 1528px) {
    max-width: calc(89vw * 9/16 - 3.75px);
    max-height: unset;
  }

  @media(max-width: 768px) {
    max-width: unset;
  }
`

const StudioImageShade = styled.span`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0,0,0,0.3);
  cursor: pointer;
  pointer-events: auto;

  :hover {
    background: rgba(0,0,0,0.5);
  }
`

const StudioImageDivider = styled.span`
  width: 5px;
  background: ${theme.palette.primary.main};

  @media(max-width: 768px) {
    width: 100vw;
    height: 5px;
  }
`

const StudioNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
`

const StudioName = styled.p`
  color: white;
  position: relative;
  font-size: 2rem;
  box-shadow: 0px -4px 5px -3px black, 0px 4px 5px -3px black;
  border-color: white;
  margin: 0;
  border-bottom: 2px solid;
  border-top: 2px solid;
  padding: 20px;
  border-radius: 10px;
`

const ArtistsTitle = styled.p`
  font-size: 1.75rem;
  color: white;
  font-weight: bold;
  width: 35%;

  @media(max-width: 768px) {
    font-size: 1.5rem;
    width: 100%;
  }
`

const CONSOLE_ITEMS = [
  'Raven MTX',
  'SSL Duality Delta 48-Channel'
]

const HEADPHONE_ITEMS = [
  'Little Red Cue Box Headphone System (x6)',
  'Aviom AN-16 with 5 A-16cs Personal Mixers'
]

const EQUALIZER_ITEMS = [
  'API 550A 3 band EQ (x2)',
  'API 560 Graphic EQ (x2)',
  'Neve 1073LB EQ 500 Series (x2)',
  'Maag EQ4 500 Series Six-Band Equalizer w/ AIR BAND',
  'GML 8200 Dual-Channel Parametric Equalizer',
  'Retro 2A3 Dual-Channel Tube Equalizer',
  'Pultec EQP-1A3 Mono Tube Equalizer - Vintage',
  'Empirical Labs LilFreq Mono Equalizer',
  'Kush Clariphonic Dual-Channel Parallel Equalizer'
]

const MONITORING_ITEMS = [
  'Augspurger G-21SHS-A3 Mains',
  'Barefoot MicroMain 27 (Gen 2)',
  'Yamaha NS10 w/ Focal CMS Sub',
  'Yamaha NS10',
  'Avantone Mixcube',
  'Auratone Mixcube',
  'SPL 2Control Stereo Monitor Controller',
  'Bryston Amplifiers'
]

const MICROPHONE_PREAMP_ITEMS = [
  'SSL XLogic 4 Channel VHD Preamp (x2)',
  'Tubetech MP1a Dual Channel Mic Preamp',
  'Neve 1073LB 500 Series Mic Preamp (x6)',
  'Focusrite RED 1 500 Series Mic Preamp (x2)',
  'Focusrite RED 7 Channel Strip (x2)',
  'Avalon VT 737-SP Channel Strip',
  'Tube-Tech MP2A Dual-Channel Tube Pre',
  'Chandler Little Devil 500 Series (x2)'
]

const COMPRESSOR_ITEMS = [
  'Neve 2264a 500 Series Compressor (x2)',
  'Retro Sta-Level Gold Edition Compressor',
  'Universal Audio 1176LN Limiting Amplifier (x2)',
  'Universal Audio Teletronix LA2A Compressor',
  'Universal Audio LA-2A Leveling Amplifier',
  'Tube-Tech CL 1B Compressor',
  'Empirical Labs EL8X Distressor Compressor (x4)',
  'Standard Audio Level-Or 500 Series JFET limiter',
  'InnerTUBE Audio Atomic Squeezebox Mono Tube Compressor',
  'dbx 165 Compressors (x2) - Vintage',
  'Elysia MPressor Two-Channel Compressor'
]

const REVERB_ITEMS = [
  'Bricasti Design M7 - with remote',
]

const MICROPHONE_ITEMS = [
  'Sony C800g (x2)',
  'AKG C12VR',
  'Korby KAT Convertible',
  'Neumann U87 Ai (x2)',
  'Neumann TLM 170 R (x2)',
  'Neumann KM184 (x2)',
  'AKG 414 XL2 (x2)',
  'Royer 121 (x2)',
  'Audio Technica 4050 (x2)',
  'RCA 44BX (Vintage)',
  'Crown PZM-6D (x2)',
  'Shure SM7B (x2)',
  'Shure SM57 (x6)',
  'Sennheiser MD 421 (x5)',
  'AKG D112 (x2)',
  'Sennheiser e609',
  'Yamaha Sub Kick',
  'Shure 556B (Vintage)',
  'Shure Super 55'
]

const DRUM_ITEMS = [
  'DW Collectors Series 5 Piece Drumkit',
  'Zildjian A Custom Cymbals',
  'Ludwig Black Beauty Supra Phonic Snare Drum',
  'Brady Sheoak Block Snare Drum',
  'Pearl Piccolo Snare Drum'
]

const GUITAR_ITEMS = [
  'Gibson 2014 Les Paul Custom Maple Top ‘60s Slim Neck Electric Guitar',
  'Fender American Stratocaster Electric Guitar',
  'ESP LTD 7-String Ken Susi Signature Electric Guitar',
  'Martin D-28 Acoustic Guitar',
  'Taylor 814ce Acoustic Guitar'
]

const GUITAR_AMPS = [
  'Kemper Profiler Rack Rackmount Guitar Preamplifier',
  'Vox AC30 30-watt 2x12” Tube Combo',
  'Peavy 5150 mkIII 50watt w/ 4x12” Egnator Cab'
]

const BASS_GUITAR_ITEMS = [
  'Fender Jazz 4-string Electric Bass',
  'Ernie Ball Music Man Stingray 5-String Electric Bass',
  'Ernie Ball Music Man Bongo 5-String Electric Bass'
]

const KEYBOARD_ITEMS = [
  'Korg Microkorg Synthesizer and Vocoder',
  'Roland VP-03 Synthesizer and Vocoder',
  'M-Audio Axiom Pro 61-Key Midi Controller Keyboard',
  'Akai Advance 49-Key Midi Controller Keyboard',
  'Native Instruments Maschine 2',
  'Roland MV8000'
]

const SOFTWARE_ITEMS = [
  'Avid HD I/O Converters 16x16',
  'Avid HDX',
  'Pro Tools 10HD',
  'Pro Tools 12HD',
  'UAD-2 Octo Card',
  'Spectrasonics Keyscape',
  'Garritan Pipe Organs',
  'Antares Autotune 5, Evo, 7, Live, EFX',
  'Celemony Melodyne',
  'VocAlign',
  'Mixed in Key',
  'Waves Mercury',
  'DDP Creator',
  'Avid HDX DSP Cards (x2)',
  'UAD-2 Octo Card',
  'Apple Mac Pro'
]

const MISC_ITEMS = [
  'TASCAM CD-500 CD Player',
  'Radial JD6 6 Channel Rack DI (x2)',
  'Moog Ladder Filter 500 Series (x2)',
  'Elysia Nvelope Stereo Impulse Shaper',
  'Empirical Labs EL-DS DERRESSER 500 Series De-esser',
  'Waves',
  'Slate Digital',
  'UAD',
  'Izotope',
  'Celemony',
  'Antares',
  'Brainworx',
  'Fabfilter',
  'Avid',
  'Lexicon',
  'Little Labs Red Eye 3D Phatom Reamp/Direct Boxes',
  'McDSP',
  'Monster cabling'
]

interface IndexPageProps {
  location: WindowLocation,
  children: ReactNode[]
}

interface IndexPageState {
  isMobile: boolean,
  toggledImage: string | undefined
}

export default class Index extends PureComponent<IndexPageProps, IndexPageState> {
  constructor(props: IndexPageProps) {
    super(props)

    this.toggleImage = this.toggleImage.bind(this)

    this.state = {
      isMobile: false,
      toggledImage: undefined
    }
  }

  componentDidMount() {
    this.setState({
      isMobile: typeof window !== 'undefined' && window.innerWidth <= 768
    })
  }

  toggleImage = (event: SyntheticEvent<ImgHTMLAttributes<HTMLImageElement>>) : void => {
    this.setState({
      toggledImage: event.currentTarget ? event.currentTarget.src : undefined
    })
  }

  render() {
    const modalizedImage = this.state.toggledImage ? (
      <Modal handleClose={this.toggleImage}>
        <img 
          src={this.state.toggledImage} 
          alt="Toggled"
          style={{
            margin: '0',
            maxHeight: '90vh',
            maxWidth: '99vw', 
            objectFit: 'contain',
            padding: '40px'
          }}/>
      </Modal>
    ) : null

    const sliderSettings = {
      dots: false,
      infinite: true,
      autoplaySpeed: 3000,
      autoplay: true,
      slidesToShow: this.state.isMobile ? 1 : 3,
      slidesToScroll: 1
    }
          
    return (
      <Layout location={this.props.location}>
        <Helmet
          link={[
            { 'href': 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css', 'rel':'stylesheet', 'charset': 'UTF-8' },
            { 'href': 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css', 'rel':'stylesheet', 'charset': 'UTF-8' }
          ]} />
        <FirstSectionWrapper>
          <FirstSection src={require('../images/studio-mic.jpg')} alt="mic"/>
          <FirstSectionTextWrapper>
            <FirstSectionText top={120}>STRANGE</FirstSectionText>
            <FirstSectionText top={150}>LAND</FirstSectionText>
            <FirstSectionText top={180}>RECORDING</FirstSectionText>
            <FirstSectionText top={210}>STUDIOS</FirstSectionText>
          </FirstSectionTextWrapper>
        </FirstSectionWrapper>
        <HeaderBar>
          <HeaderBarText>State of The Art Studios</HeaderBarText>
          <HeaderBarBottom/>
        </HeaderBar>
        <StudioImageWrapper>
          <Link to="/studios#studio-a">
            <StudioImageWrapperA>
              <StudioImageShade/>
              <StudioNameWrapper>
                <StudioName>A</StudioName>
              </StudioNameWrapper>
              <img src={require('../images/studio-a/studio-a-console.jpg')} alt="console" style={{
                width: '100%',
                height: '100%',
                margin: 0
              }}/>
            </StudioImageWrapperA>
          </Link>
          <StudioImageDivider/>
          <Link to="/studios#studio-b">
            <StudioImageWrapperB>
              <StudioImageShade/>
              <StudioNameWrapper>
                <StudioName>B</StudioName>
              </StudioNameWrapper>
              <img src={require('../images/studio-b/studio-b-control-room-2.jpg')} alt="console 2" style={{
                width: '100%',
                height: '100%',
                margin: 0
              }}/>
            </StudioImageWrapperB>
          </Link>
        </StudioImageWrapper>
        <HeaderBar>
          <HeaderBarText>Equipment<br><br>Strange Land</HeaderBarText>
          <HeaderBarBottom/>
        </HeaderBar>
        <Section 
          width="100%" 
          margin="0" 
          background="whitesmoke"
          padding="20px">
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}>
            <EquipmentItem 
              imgSrc={require('../images/equipment/console.png')}
              alt="console" 
              title="Consoles" 
              imageWidth="80%"
              items={CONSOLE_ITEMS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/monitoring.png')}
              alt="monitors" 
              title="Monitors" 
              imageHeight="70%"
              items={MONITORING_ITEMS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/microphone.png')}
              alt="microphone" 
              title="Microphones" 
              imageHeight="90%"
              items={MICROPHONE_ITEMS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/preamp.png')} 
              alt="microphone preamp" 
              title="Microphone Preamps" 
              imageWidth="80%"
              items={MICROPHONE_PREAMP_ITEMS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/headphones.png')} 
              alt="headphones" 
              title="Headphones" 
              imageHeight="70%"
              items={HEADPHONE_ITEMS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/equalizer.png')} 
              alt="equalizer" 
              title="Equalizers" 
              imageWidth="80%"
              items={EQUALIZER_ITEMS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/compressor.png')} 
              alt="compressor" 
              title="Compressors" 
              imageWidth="80%"
              items={COMPRESSOR_ITEMS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/reverb.png')} 
              alt="reverb" 
              title="Reverb" 
              imageWidth="80%"
              items={REVERB_ITEMS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/keyboards.png')} 
              alt="keyboards" 
              title="Keyboards" 
              imageWidth="80%"
              items={KEYBOARD_ITEMS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/guitar.png')} 
              alt="guitar" 
              title="Guitars" 
              imageHeight="80%"
              items={GUITAR_ITEMS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/bass.png')} 
              alt="bass" 
              title="Basses" 
              imageHeight="80%"
              items={BASS_GUITAR_ITEMS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/amp.png')} 
              alt="guitar amplifiers" 
              title="Guitar Amplifiers" 
              imageHeight="60%"
              items={GUITAR_AMPS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/drums.png')} 
              alt="drums" 
              title="Drums" 
              imageWidth="70%"
              items={DRUM_ITEMS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/software.png')} 
              alt="software" 
              title="Software" 
              imageWidth="80%"
              items={SOFTWARE_ITEMS}/>
            <EquipmentItem 
              imgSrc={require('../images/equipment/misc.png')} 
              alt="miscellaneous" 
              title="Miscellaneous" 
              imageWidth="70%"
              items={MISC_ITEMS}/>
          </div>
        </Section>
        <Section 
          flex={1}
          background="#313131"
          width="100%"
          margin="0"
          padding="20px"
          flexDirection="column">
          <ArtistsTitle>Strange Land Recording Studios has hosted sessions by such artists as</ArtistsTitle>
          <div style={{
            width: '90vw',
            margin: '0 auto'
          }}>
            <Slider {...sliderSettings}>
              <DisplayItem imageSrc={require('../images/artists/techN9ne.jpg')} text="Tech N9ne"/>
              <DisplayItem imageSrc={require('../images/artists/lilWayne.jpg')} text="Lil Wayne"/>
              <DisplayItem imageSrc={require('../images/artists/garyClarkJr.jpg')} text="Gary Clark Jr."/>
              <DisplayItem imageSrc={require('../images/artists/wanyaMorris.jpg')} text="Wanya Morris"/>
              <DisplayItem imageSrc={require('../images/artists/jWhite.jpg')} text="J White"/>
              <DisplayItem imageSrc={require('../images/artists/sza.jpg')} text="SZA"/>
              <DisplayItem imageSrc={require('../images/artists/e40.jpg')} text="E-40"/>
              <DisplayItem imageSrc={require('../images/artists/krizzKaliko.png')} text="Krizz Kaliko"/>
              <DisplayItem imageSrc={require('../images/artists/rittz.jpg')} text="Rittz"/>
              <DisplayItem imageSrc={require('../images/artists/murs.jpg')} text="MURS"/>
              <DisplayItem imageSrc={require('../images/artists/cesCru.jpg')} text="Ces Cru"/>
              <DisplayItem imageSrc={require('../images/artists/prozak.jpg')} text="Prozak"/>
              <DisplayItem imageSrc={require('../images/artists/bigScoob.png')} text="Big Scoob"/>
              <DisplayItem imageSrc={require('../images/artists/stevieStone.jpg')} text="Stevie Stone"/>
              <DisplayItem imageSrc={require('../images/artists/jl.jpg')} text="JL"/>
              <DisplayItem imageSrc={require('../images/artists/mayday.jpg')} text="¡MAYDAY!"/>
              <DisplayItem imageSrc={require('../images/artists/darreinSafron.jpg')} text="Darrein Safron"/>
              <DisplayItem imageSrc={require('../images/artists/mackenzieNicole.jpg')} text="Mackenzie Nicole"/>
              <DisplayItem imageSrc={require('../images/artists/aboveWaves.jpg')} text="Above Waves"/>
              <DisplayItem imageSrc={require('../images/artists/brothaLynchHung.jpg')} text="Brotha Lynch Hung"/>
            </Slider>`
          </div>
        </Section>
        {modalizedImage}
      </Layout>
    )
  }
}