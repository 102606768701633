import React, { PureComponent } from 'react'
import styled from 'styled-components'
import throttle from 'lodash/throttle'

import theme from '../theme'
import Modal from '../components/modal'

type Props = {
    imgSrc: string,
    imageHeight?: string|null,
    imageWidth?: string|null,
    alt: string,
    title: string,
    items: string[]
}

type State = {
    isMobile: boolean,
    isHovered: boolean,
    isClicked: boolean,
    x: number,
    y: number
}

const EquipmentItemContainer = styled.div`
  width: 270px;
  margin-bottom: 40px;
`

const EquipmentItemImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  transform: rotateZ(45deg);
  border-radius: 4px;
  background: ${theme.palette.primary.light};
  border-bottom: 10px solid;
  border-color: ${theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
  box-shadow: 2px 4px 15px -6px black;
  cursor: pointer;
`

const EquipmentItemImage = styled.img<{imageHeight?: string|null, imageWidth?: string|null}>`
  transform: rotateZ(-45deg);
  height: ${props => props.imageHeight ? props.imageHeight : 'auto'};
  width: ${props => props.imageWidth ? props.imageWidth : 'auto'};
  pointer-events: none;
`

const EquipmentItemTitle = styled.p`
  font-size: 1rem;
  font-weight: 500;
  margin: 40px 0 0 0;
  text-align: center;
`

export default class EquipmentItem extends PureComponent<Props, State> {    
    constructor(props: Props) {
        super(props)

        this.div = React.createRef()
        this.state = {
            isMobile: false,
            isHovered: false,
            isClicked: false,
            x: -1,
            y: -1
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateMobileState)
        this.updateMobileState()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateMobileState)
    }

    updateMobileState = () => {
        this.setState({
            isMobile: window.innerWidth <= 520 ? true : false
        })
    }

    captureMovement = (e) => {
        const rect = this.div.current.getBoundingClientRect()
        console.log('RECT: ', rect)

        this.setState({
            isHovered: !this.state.isHovered,
            x: rect.x - 3,
            y: rect.y + window.scrollY + 220 // 100 (item height) + 80 (top + bottom margin) + 40 (item title height and margin)
        })
    }

    updateClicked = () => {
        this.setState({
            isClicked: !this.state.isClicked
        })
    }

    render() {
        const content = !this.state.isMobile ? (
            <EquipmentItemContainer ref={this.div}>
                <EquipmentItemImageWrapper onMouseOver={throttle(this.captureMovement)} onMouseLeave={throttle(this.captureMovement)}>
                    <EquipmentItemImage 
                        src={this.props.imgSrc} 
                        alt={this.props.alt} 
                        imageHeight={this.props.imageHeight} 
                        imageWidth={this.props.imageWidth}/>
                </EquipmentItemImageWrapper>
                <EquipmentItemTitle>
                    {this.props.title}
                </EquipmentItemTitle>
            </EquipmentItemContainer>
        ) : this.state.isMobile ? ( 
            <EquipmentItemContainer ref={this.div}>
                <EquipmentItemImageWrapper onClick={this.updateClicked}>
                    <EquipmentItemImage 
                        src={this.props.imgSrc} 
                        alt={this.props.alt} 
                        imageHeight={this.props.imageHeight} 
                        imageWidth={this.props.imageWidth}/>
                </EquipmentItemImageWrapper>
                <EquipmentItemTitle>
                    {this.props.title}
                </EquipmentItemTitle>
            </EquipmentItemContainer>
        ) : null

        const dialog = this.state.isHovered ? (
            <FloatingDialog items={this.props.items} x={this.state.x} y={this.state.y}/>
        ) : this.state.isClicked ? (
            <Modal handleClose={this.updateClicked}>
                <ul style={{ marginBottom: 0, padding: '20px' }}>
                    {
                        [...this.props.items.map((item, index) => (
                                <li key={item+'-'+index} style={{ color: 'white' }}>{item}</li>
                            ))
                        ]
                    }
                </ul>
            </Modal>
        ) : null

        return (
            <>
                {content}
                {dialog}
            </>
        )
    }
}

// -100 for the height
// +40 for margin
const FloatindDialogContainer = styled.div<{x: number, y: number}>`
    width: 280px;
    padding: 10px;
    background: white;
    border-radius: 4px;
    border: 2px solid #ececec;
    position: absolute;
    z-index: 99999;
    top: ${props => props.y ? props.y +'px' : 'unset'};
    left: ${props => props.x ? props.x +'px' : 'unset' };

    &:after, :before {
        bottom: 100%;
        left: 50%;
        border: solid #ececec;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:before {
        border-color: rgba(194, 225, 245, 0);
        border-bottom-color: #ececec;
        border-width: 12px;
        margin-left: -12px;
    }

    &:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: white;
        border-width: 10px;
        margin-left: -10px;
    }
`

type FloatingDialogProps = {
    items: string[],
    x: number,
    y: number,
    height?: number,
}

const FloatingDialog: React.SFC<FloatingDialogProps> = ({ items, x, y }) => {
    return(
        <FloatindDialogContainer x={x} y={y}>
            <ul style={{ marginBottom: 0 }}>
                {
                    [...items.map(item => (
                        <li style={{ fontSize: '16px', marginBottom: '5px' }}>{item}</li>
                    ))]
                }
            </ul>
        </FloatindDialogContainer>
    )
}