import React, { PureComponent } from 'react'
import styled from 'styled-components'
import {
  Carousel,
  CarouselItem,
  CarouselIndicators
} from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.css'

import Theme from '../theme'

const CarouselWrapper = styled(Carousel)`
  &&& {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
  }
`  

const Item = styled(CarouselItem)`
  &&& {
    &.active {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: ${(props: { count: number }) => props.count > 3 ? '100px' : '0'};
    }
  }
`

const Indicators = styled(CarouselIndicators)`
  &&& {      
    position: relative;
    margin: 40px auto 0 auto;

    > li {
      background: rgba(0, 0, 0, 0.2);
      width: 15px;
      height: 15px;
      border-radius: 7.5px;
      display: ${(props: { count: number }) => props.count > 3 ? 'inline-block' : 'none'};

      &.active {
        background: ${Theme.palette.primary.light};
      }
    }
  }
`

type ImageCarouselProps = {
  items: Array<{
    imageSrc: string,
    text: string
  }>,
  style?: {}
}

type ImageCarouselState = {
  activeIndex: number
}

export default class ImageCarousel extends PureComponent<ImageCarouselProps, ImageCarouselState> {
  private isAnimating: boolean

  constructor(props: ImageCarouselProps) {
    super(props)
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)

    this.isAnimating = false

    this.state = {
      activeIndex: 0 
    }
  }

  onExiting() : void {
    this.isAnimating = true
  }

  onExited() : void {
    this.isAnimating = false
  }

  next() : void {
    if (this.isAnimating || this.props.items.length <= 3) { 
      return
    }

    const nextIndex = this.state.activeIndex === Math.ceil(this.props.items.length / 3) - 1 ? 0 : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous() : void {
    if (this.isAnimating || this.props.items.length <= 3) { 
      return
    }

    const nextIndex = this.state.activeIndex === 0 ? Math.ceil(this.props.items.length / 3) - 1 : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex: number) : void {
    if (this.isAnimating || this.props.items.length <= 3) {
      return
    }

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    const tempSlides = this.props.items.map((item, index) => {
      return (
        <DisplayItem 
          key={`${index}-${item.text}`}
          imageSrc={item.imageSrc}
          text={item.text} />
      )
    })

    const slides = []
    let counter = 0

    while (tempSlides.length) {
      slides.push(
        <Item
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={counter}>
          {tempSlides.splice(0, 3)}
        </Item>
      )

      counter++
    }

    return (
      <CarouselWrapper
        {...this.props}
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
        ride="carousel"
        style={this.props.style}>
        {slides}
        <Indicators 
          count={this.props.items.length}
          items={slides} 
          activeIndex={activeIndex} 
          onClickHandler={this.goToIndex} />
      </CarouselWrapper>
    )
  }
}

const DisplayItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //width: 250px;
  margin: 20px;
`

const DisplayItemImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 75px;
  box-shadow: 0px 2px 10px -2px black;
  object-fit: cover;
`

const DisplayItemText = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: white;
  text-align: center;
  margin-top: 20px;
`

type DisplayItemProps = {
  imageSrc: string,
  text: string
}

export const DisplayItem: React.SFC<DisplayItemProps> = ({imageSrc, text}) => {

  return(
    <DisplayItemWrapper>
      <DisplayItemImage src={imageSrc} alt="artist"/>
      <DisplayItemText>{text}</DisplayItemText>
    </DisplayItemWrapper>
  )
}